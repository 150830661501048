// Horizontal line
hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid $black;
}

.full-height-box {
	.text-box {
		height: calc(100% - 30px);

		@media(max-width: 767px) {
			margin-bottom: 30px;
		}
	}
}

// How it works
// ------------------------------------
.ba-image {
	@media(max-width:767px) {
		margin-bottom: 20px;
	}
}

// Media & Exibition
// ------------------------------------
.media-wrap {
	margin-bottom: 40px;

	img {
		margin-bottom: 20px;
	}
}

// About page
// ------------------------------------

.about-wrapper {
	max-width: 77%;
	margin: 0 auto;
	text-align: justify;

	@media(max-width: 767px) {
		max-width: 100%;
	}
}

.team-wrapper {
	background-color: #f4f4f4;

	.member-img {
		margin: 0 auto;
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		border: 4px solid #f5f5f5;
		box-shadow: 0 15px 35px -10px rgba(0, 0, 0, 0.2);
		height: 256px;
		width: 256px;
		margin-bottom: 30px;

		@media(max-width: 991px) {
			height: 215px;
			width: 215px;
		}

		img {
			width: 100%;
		}
	}

	.member-info {
		h4 {
			font-weight: 700;
			margin-bottom: 6px;
			font-size: 18px;
			text-transform: capitalize;
		}

		span {
			font-style: italic;
			font-size: 15px;
			color: rgba(33, 37, 41, 0.6);
			margin-bottom: 10px;
		}

		@media(max-width: 767px) {
			margin-bottom: 30px;
		}
	}
}

// Feature page
.feature-box {
	box-shadow: 0px 0 15px 0 rgba(0, 0, 0, 0.2);
	padding: 20px;
	transition: all ease-in-out 0.4s;
	background: #fff;
	margin-bottom: 24px;
	height: calc(100% - 24px);

	img {
		width: 80px;
	}
}

// Clientele page
.client-list-page {
	background-color: #767676;
	color: white;

	img {
		width: 100%;
	}

	h4 {
		color: white;
	}

	.cbp-l-grid-projects-title {
		text-align: center;
		color: white;
		font-weight: 700;
		font-size: 14px;
		margin-top: 15px;
	}

	hr {
		border-color: white;
	}

	.cbp-l-grid-projects-desc {
		font: 400 12px / 18px "Open Sans", sans-serif;
		margin-top: 5px;
		text-align: center;
		margin-bottom: 20px;
	}
}

// Scale defence page
.scale-defence-wrap {
	.slideshow-container {
		.slick-arrow {
			border-radius: 3px !important;
		}

		img {
			margin: 0 auto;
		}
	}
}

// Blog page
.accordion-item {
	margin-bottom: 1px;

	.accordion-button {
		background-color: $primary !important;
		color: $white !important;
		font-size: 20px;
		position: relative;

		@media(max-width: 767px) {
			font-size: 17px;
			line-height: 26px;
		}

		&::after {
			filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(273deg) brightness(103%) contrast(103%);
		}

		&:focus {
			box-shadow: none;
		}
	}

	.accordion-body {
		border: 1px solid $primary;
	}
}


// feature box 29
.ce4-feature-box-29 {
	width: 100%;
	float: left;

	.icon {
		color: $white;
		background-color: $primary !important;
	}

	.title {
		font-size: 20px;
	}
}

.experts-wrapper {

	img {
		width: 100%;
	}

	.ce4-feature-box-39 {
		width: 100%;
		border: 1px solid $primary;

		@media(max-width: 767px) {
			margin-bottom: 20px;
		}

		.img-box {
			position: relative;
			width: 100%;

			.text-box {
				position: absolute;
				padding: 15px;
				bottom: 0px;
				z-index: 3;
				background-color: $primary;
				transition: all 0.3s ease-out 0s;
			}
		}
	}
}

// Modal design
.modal-header {
	flex-direction: row-reverse;

	.close {
		color: $primary;
		background-color: transparent;
		border: none;
		font-size: 20px;
	}
}

/* =========== domain pricing table ============= */
.tbl-res {
	overflow-x: unset;
}

.title-wrap {
	padding: 0 15px 1px;
}

.domain-pricing-table {
	width: 100%;
	padding: 20px;
	margin: 0px;
	background-color: #f1f1f1;
	overflow-x: auto;

	td {
		background-color: transparent;
	}

	.table-style-2 {
		width: 100%;
		padding: 0px;
		margin: 0px;
		float: left;

		table {
			width: 100%;
			border-collapse: collapse;
		}

		tr {
			&:nth-of-type(odd) {
				background: #f1f1f1;
			}

			&:nth-of-type(even) {
				background: $white;
			}
		}

		th {
			background: #242424;
			color: $white;
			font-weight: bold;
		}

		td,
		th {
			padding: 16px;
			border: none;
			text-align: center;
		}
	}
}


// Kashyap Auto Electronics
.product-bg {
	background-color: #f7f7f7;
}

.magnific-img {
	position: relative;
	margin-bottom: 20px;
	transition: all 0.3s ease 0s;

	.image-wrap {
		position: relative;

		&::before {
			content: '';
			background-color: #99CC33;
			position: absolute;
			opacity: 0.85;
			height: 100%;
			width: 100%;
			display: none;
		}
	}

	&:hover {
		.image-wrap {
			&::before {
				display: block;
			}
		}

		.fa-search {
			display: block;
		}
	}

	.image-popup-vertical-fit {
		display: inline-block;
		cursor: pointer;

		img {
			transition: all 0.3s ease 0s;
		}
	}

	.fa-search {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 16px;
		color: gray;
		border: 2px solid white;
		padding: 13px;
		border-radius: 50%;
		background-color: white;
		transition: all 0.2s ease 0s;
		display: none;

		&:hover {
			background-color: transparent;
			color: white;
		}
	}

	.product-title {
		background-color: white;
		font-weight: 600;
		text-align: center;
		padding: 18px 15px;
	}
}


// .mfp-bottom-bar,
// * {
// 	font-family: 'Abel', sans-serif;
// }

a.image-popup-vertical-fit {
	cursor: -webkit-zoom-in;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	/* ideally, transition speed should match zoom duration */
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.98;
	height: 100vh !important;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

/* Magnific Popup CSS */
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.8;
}

.mfp-wrap {
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 8px;
	box-sizing: border-box;
}

.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	color: #CCC;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 1044;
}

.mfp-preloader a {
	color: #CCC;
}

.mfp-preloader a:hover {
	color: #FFF;
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;

	&:hover,
	&:focus {
		opacity: 1;
	}

	&:active {
		top: 1px;
	}
}

.mfp-close-btn-in .mfp-close {
	color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	color: #FFF;
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%;
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #CCC;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}

.mfp-arrow {
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
		z-index: 9999;
	}

	&.mfp-arrow-left {
		&::after {
			border-right: 15px solid white;
			left: 15px;
		}
	}

	&.mfp-arrow-right {
		&::after {
			border-left: 15px solid white;
			right: 15px;
		}
	}

}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;

	.mfp-content {
		line-height: 0;
		width: 100%;
		max-width: 900px;
	}

	.mfp-close {
		top: -40px;
	}
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;

	iframe {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
		background: #000;
	}
}

/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
	line-height: 0;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 40px;
		bottom: 40px;
		display: block;
		right: 0;
		width: auto;
		height: auto;
		z-index: -1;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
		background: #444;
	}

	small {
		color: #BDBDBD;
		display: block;
		font-size: 12px;
		line-height: 14px;
	}

	figure {
		margin: 0;
	}
}

.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #F3F3F3;
	word-wrap: break-word;
	padding-right: 36px;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

	/**
       * Remove all paddings around the image on small screen
       */
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}

	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}

	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}

	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}

	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		box-sizing: border-box;
	}

	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}

	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}

	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}

@media all and (max-width: 900px) {

	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}

// Contact page
.contact-page {
	.form-footer {
		.btn-primary {
			@media(max-width: 377px) {
				margin-bottom: 10px;
			}
		}
	}

	h4 {
		@media(max-width: 991px) {
			font-size: 20px;
		}
	}

	.text-box.padding-3 {
		padding-bottom: 10px;

		@media(max-width: 575px) {
			padding: 15px;
		}
	}

	.info-wrap {
		.info-item {
			i {
				font-size: 20px;
				color: $primary;
				background: rgba($primary, 0.08);
				width: 44px;
				height: 44px;
				min-width: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50px;
				transition: all 0.3s ease-in-out;
				margin-right: 15px;
			}

			h6 {
				margin-bottom: 5px;
				font-weight: bold;
			}


		}
	}
}

// Product page
.product-page {
	img {
		@media(max-width: 575px) {
			width: 100%;
		}
	}
}
.footer-logo {
    margin-bottom: 25px;
    text-align: center;

    img {
        width: 200px;
    }
}

.section-dark {
    // background-color: $primary;
    // background-color: #3f3e3e;
    background-color: #2D3740;

    p,
    address,
    span {
        color: $white;
    }
}

.footer-wrap {
    position: relative;
    padding: 50px 0;

    @media(max-width: 767px) {
        padding: 30px 0 50px;
    }
}

/*============ Footer Styles Sections ============ */

/* footer section
 ------------------------------ */
.fo-map {
    width: 100%;
    float: left;
    background-size: 100%;
    background: url("../assets/images/fo-map.png") left 42px no-repeat;

    p {
        text-align: justify;
    }
}

/* footer title bottom line
 ------------------------------ */
.fo-title-bottom-line {
    width: 34px;
    height: 2px;
    margin-bottom: 20px;
    background-color: $white;
}

/* ---------- Footer quick links 4---------- */
.quick-links {
    margin-left: 100px !important;

    @media(max-width: 1199px) {
        margin-left: 70px !important;
    }

    @media(max-width: 991px) {
        margin-left: 20px !important;
    }

    @media(max-width: 767px) {
        margin-left: 0 !important;
        margin-bottom: 20px;
    }

}

.footer-quick-links-4 {
    width: 100%;
    padding: 0 0 0 0;
    margin: 0px;

    li {
        width: 100%;
        padding: 0px;
        margin: 0;
        display: inline-block;

        a {
            padding: 3px 0;
            margin: 0;
            color: $white;
            display: inline-block;

            i {
                padding: 0 10px 0 0;
            }
        }
    }
}

.social-iconbox {
    position: absolute;
    width: 265px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -26px;
    text-align: center;
    background-color: $white;

    .side-shape1 {
        position: absolute;
        width: 13px;
        left: -13px;
        top: -1px;

        &.right-icon {
            right: -13px;
            left: auto;
        }
    }

    .sc-icons {
        width: 100%;
        float: left;
        padding: 0px;
        margin: 0px;

        li {
            padding: 0px;
            margin: 0px;
            display: inline-block;

            a {
                outline: medium none !important;
                color: #727272;
                font-size: 15px;
                padding: 15px 10px;
                margin: 0px;
                display: inline-block;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

/* Footer copyright box
 ------------------------------ */

.fo-copyright-holder {
    padding: 30px 0 20px;
}
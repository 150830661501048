@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}

.navbar {
    background-color: $white;
    margin-bottom: 0;
    padding: 0;

    &.fixed-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        animation: slide-down 0.7s;
        box-shadow: 0px 0px 10px 0px;

        .navbar-nav {
            li {
                a {
                    padding: 25px 10px !important;

                    @media (max-width: 1199px) {
                        padding: 15px 25px !important;
                    }
                }

                .dropdown-item {
                    padding: 12px 23px !important;
                }
            }
        }
    }

    @media(max-width: 1199px) {
        padding: 10px 0;
    }

    .navbar-brand {
        width: 200px;

        @media(max-width: 767px) {
            width: 180px;
        }
    }

    .navbar-collapse {
        justify-content: flex-end;

        .navbar-nav {
            @media(max-width: 1199px) {
                height: calc(100vh - 60px);
                overflow-y: auto;
            }
        }
    }

    .navbar-toggler {
        display: none;
        border: none;
        box-shadow: none;

        @media(max-width: 1199px) {
            display: block;
        }
    }

    .navbar-nav {
        float: right;

        @media(max-width: 1199px) {
            width: 100%;
        }

        li {
            @media(max-width: 1199px) {
                border-bottom: 1px solid #ececec;
            }

            a {
                text-transform: uppercase;
                color: $codGray;
                padding: 35px 10px !important;

                @media(max-width: 1199px) {
                    padding: 15px 25px !important;
                    text-transform: capitalize;
                }

                &.active {
                    color: $primary;
                }
            }

            ul {

                display: none;
                width: 240px;
                position: absolute;
                left: 0;
                padding: 0;
                background-color: $white;
                z-index: 9999;
                padding-left: 0;
                min-width: max-content;
                margin-left: -100px;
                border: none;
                border-radius: 0;

                @media(max-width: 1199px) {
                    position: unset;
                    margin-left: 30px;
                }

                li {
                    border-bottom: 1px solid #f1f1f1;

                    @media(max-width: 1199px) {
                        border-bottom: none;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    a {
                        text-transform: capitalize;
                        transition: all 0.3s ease-out 0s;
                        padding: 12px 23px !important;
                        display: inline-block;
                        width: 100%;
                        font-size: 14px;

                        &:hover {
                            color: $primary;
                            margin-left: 8px;
                        }

                        &.active {
                            background-color: transparent;
                        }
                    }
                }
            }

            &:hover {
                >a {
                    color: $primary;
                    background-color: transparent;
                }

                ul {
                    display: block;
                }
            }
        }
    }
}
// paddings
.p-5 {
    padding: 2em !important;
}

.pb-5 {
    padding-bottom: 10px !important;
}

.pb-0 {
    padding-bottom: 0;
}

.pt-5 {
    padding-top: 25%;
}

.pt-3 {
    padding-top: 15%;
}

.pt-2 {
    padding-top: 10%;
}

.pt-1 {
    padding-top: 5%;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

.animation {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

body {

    &.nav-active,
    &.mfp-zoom-out-cur {
        overflow: hidden;
    }
}

// page banner

.page-banner {
    background-image: url("../assets/images/inner-back.webp") !important;
    background-repeat: no-repeat;
    height: 220px;
    position: relative;
    background-size: cover;
    background-position: center;

    @media(max-width: 767px) {
        height: 175px;
    }

    &::before {
        content: '';
        background-color: rgba(19, 98, 144, 0.7);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .page-title {
        position: absolute;
        z-index: 99;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h3 {
            text-transform: uppercase;
            color: $white;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 0;
            text-align: center;

            @media(max-width: 767px) {
                font-size: 22px;
            }
        }
    }
}

/* title line
 ------------------------------ */
.ce4-title-line-1 {
    width: 50px;
    height: 2px;
    margin: 0px auto 10px auto;
    background-color: $codGray;

    &.align-left {
        width: 50px;
        margin: 0 auto 20px 0;
    }
}

// social icons (sticky)

.social-wrap {
    left: 0;
    bottom: 120px;
    position: fixed;
    z-index: 9;

    ul {
        padding-left: 0;

        li {
            margin-bottom: 10px;
            width: 45px;
            height: 45px;

            a {
                padding: 10px;
                background: $blue;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                height: 100%;

                &:hover {
                    background-color: $secondary;
                }
            }
        }
    }
}

/* image rounded
 ------------------------------ */

.img-rounded {
    border-radius: 8px;
}


/*============ Feature boxes ============ */
.p-5 {
    padding: 2em;
}

.tp-sec-bg {
    width: 100%;
    background: #f5f5f5;
}

/*============ Section Titles ============ */

/* title line
 ------------------------------ */
.tp-title-line-1 {
    width: 70px;
    height: 2px;
    margin: 15px auto 10px auto;
    background-color: $codGray;

    &.align-left {
        width: 70px;
        margin: 8px auto 20px 0;
    }

    &.dark {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    &.white {
        background-color: $white;
    }
}

// Loader design
.over-loader {
    background: #ed5565;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    // display: none;

    &.loader-live {
        z-index: 9999991;
        background: #fff;
    }

    .loader {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        z-index: 99999;
    }

    .loader-item.style4 {
        width: 40px;
        height: 40px;
        position: relative;

        .cube1,
        .cube2 {
            background-color: #136290 !important;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
            animation: sk-cubemove 1.8s infinite ease-in-out;
        }

        .cube2 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }
    }
}

/* line 23, ../sass/screen.scss */
.hide-loader {
    display: none;
}

@keyframes sk-cubemove {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }

    50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    }

    50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }

    75% {
        transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    }

    100% {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}

// Animation after loader
.animation-wrap {
    background-color: #F2F1EF;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 99;
    text-align: center;

    .animate {
        width: 100%;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        // margin: 100px 0 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // border-bottom: 2px solid #ccc;
        z-index: 999;

        @media(max-width: 767px) {
            font-size: 40px;
        }

        @media(max-width: 420px) {
            font-size: 25px;
        }

        img {
            margin-bottom: 20px;
            max-width: 500px;

            @media(max-width: 500px) {
                max-width: 100%;
            }
        }

        span {
            color: $primary;
            opacity: 0;
            text-transform: uppercase;
            transform: translate(-150px, 0) scale(.3);
            animation: leftRight 1.5s forwards;
            display: inline-block;

            &:nth-of-type(2) {
                animation-delay: .1s;
            }

            &:nth-of-type(3) {
                animation-delay: .2s;
            }

            &:nth-of-type(4) {
                animation-delay: .3s;
            }

            &:nth-of-type(5) {
                animation-delay: .4s;
            }

            &:nth-of-type(6) {
                animation-delay: .5s;
            }

            &:nth-of-type(7) {
                animation-delay: .6s;
            }

            &:nth-of-type(8) {
                animation-delay: .7s;
            }

            &:nth-of-type(9) {
                animation-delay: .8s;
            }

            &:nth-of-type(10) {
                animation-delay: .9s;
            }

            &:nth-of-type(11) {
                animation-delay: 1s;
            }

            &:nth-of-type(12) {
                animation-delay: 1.1s;
            }
        }
    }
}

@keyframes leftRight {
    40% {
        transform: translate(50px, 0) scale(.7);
        opacity: 1;
        color: $blue;
    }

    60% {
        color: $secondary;
    }

    80% {
        transform: translate(0) scale(2);
        opacity: 0;
    }

    100% {
        transform: translate(0) scale(1);
        opacity: 1;
    }
}
// inquiry button

.btn-custom {
    color: $white;
    background-color: #242424;
    border-color: #242424;
    font-size: 15px;
}

/* button styles
 ------------------------------ */

.btn {
    padding: 10px 36px;
    margin: 0px;
    box-shadow: none;
    border-radius: 0;
    font-size: 14px;

    &.btn-dark-3 {
        color: $white;
        background-color: $secondary;

        &:hover {
            color: $white;
            background-color: $primary !important;
        }
    }

    &.btn-gyellow-2 {
        color: $white;
        background-color: $primary !important;
        border: 1px solid white;

        &:hover {
            color: $codGray;
            background-color: $white !important;
        }
    }

    &.btn-border {
        color: #242424;
        border: 2px solid;

        &.dark {
            border-color: $secondary;

            &:hover {
                box-shadow: inset 11em 0 0 0 $secondary;
                color: white;
            }
        }
    }
}
/* Slideshow container */
.mySlides {
    display: none
}

.desktop-slide {
    display: block !important;

    @media (max-width:767px) {
        display: none !important;
    }
}

.mobile-slide {
    display: none !important;
    width: 100%;
    height: auto !important;

    @media (max-width:767px) {
        display: block !important;
    }
}

// .user-slider-wrapper {
//     background-color: gray;

//     h3 {
//         color: white;
//     }

//     .tp-title-line-1 {
//         background-color: white;
//     }

// }

.user-slider {
    .item.slick-slide {
        margin: 20px;

        img {
            margin: 0 auto;
        }
    }

    .slick-arrow {
        display: none !important;
    }

    .slick-dots {
        li {
            background-color: $primary !important;
            border: 1px solid $primary !important;
        }
    }
}

/* =========== Parallax ============= */

.parallax-49 {
    background: rgba(0, 0, 0, 0) url("../assets/images/bk2.jpg") no-repeat fixed 100% 100%;
    position: relative;
    background-position: left;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        color: #f1f1f1;
        width: 100%;
        height: 100%;
        transition: .5s ease;

    }
}

.parallax-overlay {
    position: relative;
    z-index: 9;
    padding: 0 12px;
}

.slideshow-container {
    max-width: 100%;
    position: relative;
    margin: auto;

    .slick-arrow {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 10px 5px;
        // margin-top: -22px;
        color: $white;
        font-weight: bold;
        // font-size: 18px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        background-color: $primary;
        z-index: 9;
        width: 40px;
        height: 55px;

        &::before {
            content: '\f053';
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            // font-size: 12px;
        }

        &.slick-prev {
            left: 0;
            right: auto;
        }

        &.slick-next {
            right: 0;
            left: auto;
            border-radius: 3px 0 0 3px;

            &::before {
                content: '\f054';
            }
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }



    .next {
        right: 0;
        left: auto;
    }

    .active,
    .dot:hover {
        background-color: #717171;
    }

    .text {
        color: #f2f2f2;
        font-size: 15px;
        padding: 8px 12px;
        position: absolute;
        bottom: 8px;
        width: 100%;
        text-align: center;
    }

    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }
}

.iconbox-small.left {
    float: left;
    margin: 0px 15px 0px 0px;
}

.testimonial-wrapper {
    background-color: #f5f5f5;

    .testimonial-slider {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;
        margin-bottom: 0;

        .slick-arrow {
            display: none !important;
        }

        .slick-dots {
            bottom: 25px;
            z-index: 99999;

            [type=button] {
                -webkit-appearance: none;
                appearance: none;
            }

            li {
                position: relative;
                display: inline-block;
                height: 10px;
                width: 10px;
                margin: 0 5px;
                padding: 0;
                background-color: black;
                cursor: pointer;
                opacity: 0.2;
                border-radius: 50%;

                &.slick-active {
                    background-color: $secondary;
                    opacity: 1;
                }
            }
        }
    }
}

/* feature box 2
 ------------------------------ */
.tp-feature-box-2 {
    width: 100%;
    float: left;

    .title-line {
        width: 20%;
        height: 2px;
        float: left;
        margin: 8px 0 0 0;
        background-color: $primary !important;

        &.align-right {
            float: right;
        }
    }

    .title {
        font-size: 18px;
        text-align: center;
    }

    &:hover {
        .title {
            color: $primary !important;
        }
    }

    .text-box-right {
        @media(max-width: 575px) {
            text-align: center;
        }

        &.less-padding-2 {
            @media(max-width: 991px) {
                padding: 0px 0px 0px 0px;
            }
        }
    }
}

/* feature box 3
 ------------------------------ */
.tp-feature-box-3 {
    width: 100%;
    padding: 40px;
    float: left;
    background-color: $primary;

    /*background-color: $codGray;*/
    form select.margin-top {
        margin-top: 20px;
    }
}

/* ---------- feature box 5 ---------- */
.tp-feature-box-5 {
    box-sizing: content-box;
    margin: 30px;
    margin-bottom: 50px;

    i {
        color: rgba($secondary, 0.5);
        font-size: 20px;
        line-height: 0;
        position: relative;

        &.fa-quote-left {
            left: -5px;
            top: -5px;
        }

        &.fa-quote-right {
            right: -5px;
            bottom: -10px;
        }
    }
}

/* The dots/bullets/indicators */
.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

/* Fading animation */

@media (max-width: 767px) {
    .mySlides img {
        height: 55vw;
    }

    .pt-m-1 {
        padding-top: 5%;
    }
}